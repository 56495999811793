import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAccessToken, clearTokens } from '../../../utils/tokenUtils';

const Header = () => {
  const navigate = useNavigate();
  const token = getAccessToken();
  const userId = token ? JSON.parse(atob(token.split('.')[1])).sub : null;

  const handleLogout = () => {
    clearTokens();
    navigate('/login');
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);  // ref для dropdown

  // Toggle the dropdown
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Close dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <header className="bg-gray-800 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex space-x-5 items-center">
          <Link to="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="43" height="48" viewBox="0 0 43 48" fill="none">
              <g filter="url(#filter0_i_2_14)">
                <path d="M13.0908 4.05548C8.77421 6.22872 4.94398 8.34322 4.64 8.6369C4.15362 9.10679 6.7071 10.5752 12.726 13.4533L21.4808 17.6823L29.8101 13.3945C34.3699 11.0451 38.0177 8.93058 37.8353 8.69563C37.5921 8.28448 21.1161 -0.173512 20.9337 0.00269699C20.9337 0.0614338 17.3466 1.88225 13.0908 4.05548Z" fill="white" />
                <path d="M0.0154932 25.266L0.197885 37.6593L6.27761 40.7723C9.62146 42.5344 12.6613 43.9441 13.0869 43.9441C13.6341 43.9441 13.8773 42.2407 13.9989 38.2467C14.1812 33.1954 14.3028 32.4906 15.3364 32.3143C17.1603 31.9619 17.5251 33.2541 17.5251 40.185V46.6459L19.4706 47.527C21.3553 48.408 21.4769 48.408 31.7516 43.063L42.148 37.7181L42.3304 25.2073C42.452 13.93 42.3912 12.6965 41.4792 13.0489C40.9928 13.2251 36.1898 15.5746 30.9005 18.2764C22.0849 22.7991 21.1729 23.1515 19.8354 22.4467C17.7075 21.272 17.5251 21.4482 17.5251 23.9151C17.5251 26.3233 16.3092 27.8504 14.7892 27.263C14.1205 27.0281 13.8773 26.0296 13.8773 23.3865C13.8773 20.0385 13.7557 19.8036 11.9318 18.6876C8.7703 16.808 8.46631 17.043 8.28392 21.7419C8.10153 25.8534 8.04073 26.0296 6.58159 26.0296C5.06166 26.0296 5.06166 25.9708 4.87927 20.5671C4.69688 15.1047 4.69688 15.1047 2.75137 13.9887C1.65702 13.3426 0.623466 12.814 0.319479 12.814C0.0762904 12.814 -0.045304 18.3939 0.0154932 25.266ZM8.40551 31.8445C9.56066 33.1954 8.89189 35.4274 7.31116 35.6036C4.63608 36.0147 3.23775 33.724 5.12246 31.9619C6.27761 30.7872 7.43276 30.7872 8.40551 31.8445Z" fill="white" />
              </g>
              <defs>
                <filter id="filter0_i_2_14" x="0" y="0" width="42.3772" height="51.9623" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2_14" />
                </filter>
              </defs>
            </svg>
          </Link>

          {/* Dropdown Trigger */}
          <div className="relative">
            <span className="text-slate-100 text-xl">INF.03</span>
          </div>
        </div>

        <nav>
          {userId ? (
            <div className="flex items-center space-x-4">


              <div className="relative" ref={dropdownRef}> {/* добавляем ref */}
                <button
                  onClick={toggleDropdown}
                  className="flex items-center space-x-2 focus:outline-none transition-colors hover:bg-indigo-600 rounded-lg px-3 py-2"
                >
                  <i className="fa-solid fa-user text-xl"></i>
                  <span className="text-white font-semibold">{userId}</span>
                  <i className={`fa-solid ${dropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'} text-lg`}></i>
                </button>

                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 w-56 bg-white text-black rounded-lg shadow-lg z-50 border border-gray-200">
                    <Link
                      onClick={toggleDropdown}
                      to={`/profile/${userId}`}
                      className="block px-4 py-2 hover:bg-indigo-500 hover:text-white rounded-t-lg transition-colors"
                    >
                      Profile
                    </Link>
                    {userId === 10 && (
                      <Link
                        onClick={toggleDropdown}
                        to="/admin"
                        className="block px-4 py-2 hover:bg-indigo-500 hover:text-white transition-colors"
                      >
                        Admin
                      </Link>
                    )}
                    <Link onClick={toggleDropdown} to="/tasks-list" className="block px-4 py-2 hover:bg-indigo-500 hover:text-white transition-colors">My Tasks</Link>
                    <Link onClick={toggleDropdown} to="/classes" className="block px-4 py-2 hover:bg-indigo-500 hover:text-white transition-colors">My Classes</Link>
                    <Link onClick={toggleDropdown} to="/create-class" className="block px-4 py-2 hover:bg-indigo-500 hover:text-white transition-colors">Create Class</Link>
                    <Link onClick={toggleDropdown} to="/invite-to-class" className="block px-4 py-2 hover:bg-indigo-500 hover:text-white transition-colors">Invite to Class</Link>
                    <Link
                      onClick={toggleDropdown}
                      to="/markdown-viewer?file=guides/js-pl.md"
                      className="flex justify-between items-center px-4 py-2 hover:bg-indigo-500 hover:text-white text-yellow-400 transition-colors"
                    >
                      <span className='text-black'>Java Script</span>
                      <i className="fa-brands fa-js text-4xl"></i>
                    </Link>
                    <button
                      onClick={() => {
                        handleLogout();
                        toggleDropdown();
                      }}
                      className="block w-full text-left px-4 py-2 hover:bg-red-500 hover:text-white text-black rounded-b-lg transition-colors"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="flex space-x-4">
              <Link to="/login" className="bg-blue-600 px-4 py-2 rounded-lg text-white hover:bg-blue-700 transition-colors shadow-md">Login</Link>
              <Link to="/register" className="bg-green-500 px-4 py-2 rounded-lg text-white hover:bg-green-600 transition-colors shadow-md">Register</Link>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;
