import React, { useState, useEffect } from 'react';
import { getAnswers, getTagsList } from '../../../../../utils/api'; // API call to get stats

import Swal from 'sweetalert2';

import { Chart, RadarController, RadialLinearScale, PointElement, LineElement, Filler } from 'chart.js';
Chart.register(RadarController, RadialLinearScale, PointElement, LineElement, Filler);

const Stats = () => {
    const fetchStats = async () => {
        try {
            // Fetch user answers
            const answers = await getAnswers();
    
            // Fetch tag list
            const tagsResponse = await getTagsList();
            const tags = tagsResponse.reduce((acc, [id, name]) => {
                acc[id] = name;
                return acc;
            }, {});
    
            // Compute tag-wise statistics
            const tagStats = {};
            answers.forEach(answer => {
                const [, , , isCorrect, tagId] = answer.split(',');
                if (!tagStats[tagId]) {
                    tagStats[tagId] = { total: 0, correct: 0 };
                }
                tagStats[tagId].total += 1;
                if (isCorrect === 'True') {
                    tagStats[tagId].correct += 1;
                }
            });
    
            // Prepare data for radar chart
            const labels = Object.keys(tagStats).map(tagId => tags[tagId] || 'Unknown Tag');
            const data = Object.keys(tagStats).map(tagId => {
                const { total, correct } = tagStats[tagId];
                return total > 0 ? (correct / total) * 100 : 0;
            });
    
            // Show the stats in a Swal modal
            Swal.fire({
                title: 'Statystyki',
                html: `
                    <p>Łączna liczba odpowiedzi: ${answers.length}</p>
                    <p>Liczba poprawnych odpowiedzi: ${answers.filter(answer => answer.split(',')[3] === 'True').length}</p>
                    <p>Procent poprawnych odpowiedzi: ${(
                        (answers.filter(answer => answer.split(',')[3] === 'True').length / answers.length) * 100
                    ).toFixed(2)}%</p>
                    <br />
                    <hr />
                    <br />
                    <h3>Statystyki według tagów:</h3>
                    <canvas id="tagStatsChart" width="400" height="400"></canvas>
                `,
                icon: 'info',
                confirmButtonText: 'OK',
                didOpen: () => {
                    // Initialize Chart.js when the Swal is opened
                    const ctx = document.getElementById('tagStatsChart').getContext('2d');
                    new Chart(ctx, {
                        type: 'radar',
                        data: {
                            labels: labels,
                            datasets: [{
                                label: 'Procent poprawnych odpowiedzi',
                                data: data,
                                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                                borderColor: 'rgba(54, 162, 235, 1)',
                                borderWidth: 1
                            }]
                        },
                        options: {
                            scale: {
                                ticks: { beginAtZero: true }
                            }
                        }
                    });
                }
            });
        } catch (error) {
            console.error('Nie udało się pobrać statystyk', error);
            Swal.fire({
                title: 'Błąd',
                text: 'Nie udało się pobrać statystyk',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    return (
            <button
                onClick={() => fetchStats()}
                className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
            >
                <i className="fa-solid fa-chart-column"></i>
            </button>
    );
};

export default Stats;
