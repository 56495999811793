import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Main.Module.css'; // Import the CSS file
import RadarChart from './RadarChart/RadarChart'; // Adjust the import path if necessary
import api from '../../../../utils/api'; // Ensure your API utility is correctly imported

const ForUsers = () => {
  const [cards, setCards] = useState([
    { 
        id: '9', 
        type: 'tags', 
        title: 'Wybierz Temat Testu', 
        description: 'Wybierz temat testu z dostępnej listy, aby rozpocząć. Ułatw sobie naukę!', 
        height: 1, 
        tags: [] 
    },
    { 
        id: '1', 
        type: 'link', 
        title: 'INF.03 - Jedno Pytanie', 
        description: 'Zbadaj swoją wiedzę z zakresu systemów operacyjnych i sieci komputerowych w formie jednego pytania.', 
        height: 1, 
        link: '/test?exam=2',
        icon: 'fa-laptop-code' // Add the Font Awesome icon class
    },
    { 
        id: '3', 
        type: 'stats', 
        title: 'Statystyki', 
        description: 'Zobacz szczegółowe statystyki dotyczące swoich wyników i postępów.', 
        height: 2, 
        link: '' 
    },
    { 
        id: '2', 
        type: 'link', 
        title: 'INF.03 - 40 Pytan', 
        description: 'Przygotuj się do egzaminu z 40 pytaniami dotyczącymi tworzenia i administrowania stronami internetowymi.', 
        height: 1, 
        link: '/full-test',
        icon: 'fa-file-alt' // Add the Font Awesome icon class
    },
    { 
        id: '4', 
        type: 'link', 
        title: 'Bazy Danych i SQL - Jedno Pytanie', 
        description: 'Sprawdź swoją wiedzę na temat baz danych i SQL w krótkim teście składającym się z jednego pytania.', 
        height: 1, 
        link: '/test?type=2&tags=8&tags=9',
        icon: 'fa-database' // Add the Font Awesome icon class
    },
    { 
        id: '5', 
        type: 'link', 
        title: 'JavaScript - Jedno Pytanie', 
        description: 'Zyskaj pewność siebie w JavaScript, odpowiadając na jedno kluczowe pytanie dotyczące programowania.', 
        height: 1, 
        link: '/test?type=2&tags=14',
        icon: 'fa-code' // Add the Font Awesome icon class
    },
    { 
        id: '6', 
        type: 'link', 
        title: 'HTML i CSS - Jedno Pytanie', 
        description: 'Przygotuj się do pracy z HTML i CSS, odpowiadając na to pytanie testowe.', 
        height: 1, 
        link: '/test?type=2&tags=13&tags=11',
        icon: 'fa-paint-brush' // Add the Font Awesome icon class
    },
    { 
        id: '7', 
        type: 'link', 
        title: 'PHP - Jedno Pytanie', 
        description: 'Zbadaj swoją wiedzę z PHP w formie jednego pytania dotyczącego administracji stronami i bazami danych.', 
        height: 1, 
        link: '/test?type=2&tags=12',
        icon: 'fa-server' // Add the Font Awesome icon class
    },
  ]);

  const [draggedCardId, setDraggedCardId] = useState(null);
  const [showRadar, setShowRadar] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const tagList = await getTagsList();
        setTags(tagList); // Set the tags fetched from the API
        setCards((prevCards) => prevCards.map(card => card.id === '9' ? { ...card, tags: tagList } : card)); // Add tags to the card
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    fetchTags();
    
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCards((prevCards) => {
          const reorderedCards = [...prevCards].sort((a, b) => a.height - b.height);
          return reorderedCards;
        });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    setShowRadar(true);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getTagsList = async () => {
    const response = await api.post('/tags_list/2');
    return response.data.tags.map(tag => ({ id: tag[0], name: tag[1] })); // Adjust this if necessary based on your API response
  };

  const handleTagSelect = (tagId) => {
    setSelectedTags((prevSelected) => {
      if (prevSelected.includes(tagId)) {
        return prevSelected.filter(id => id !== tagId); // Deselect if already selected
      } else {
        return [...prevSelected, tagId]; // Add to selected tags
      }
    });
  };

  const handleDragStart = (id) => {
    setDraggedCardId(id);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (id) => {
    const draggedCardIndex = cards.findIndex((card) => card.id === draggedCardId);
    const droppedCardIndex = cards.findIndex((card) => card.id === id);
    const newCards = [...cards];
    const [draggedCard] = newCards.splice(draggedCardIndex, 1);
    newCards.splice(droppedCardIndex, 0, draggedCard);
    setCards(newCards);
  };

  const getRowSpanClass = (height) => {
    return height === 2 ? 'row-span-2' : 'row-span-1';
  };

  const renderTagSelection = () => (
    <div className="flex flex-col p-4">
      <h3 className="text-xl mb-2">Wybierz Tematy:</h3>
      <div className="flex flex-wrap">
        {tags.map(tag => (
          <div key={tag.id} className="mr-4 mb-2">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                value={tag.id}
                checked={selectedTags.includes(tag.id)}
                onChange={() => handleTagSelect(tag.id)}
                className="form-checkbox h-5 w-5 text-indigo-600"
              />
              <span className="ml-2 text-gray-700">{tag.name}</span>
            </label>
          </div>
        ))}
      </div>
      <Link
        to={selectedTags.length > 0 ? `/test?type=2&tags=${selectedTags.join('&tags=')}` : '#'}
        className={`mt-4 inline-block px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${selectedTags.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={selectedTags.length === 0 ? (e) => e.preventDefault() : null}
      >
        Rozpocznij Test
      </Link>
    </div>
  );

  return (
    <div className="py-5 min-h-screen bg-[#384c64]" id="info-users">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-6 xl:px-32 auto-rows-fr h-full">
        {cards.map((card) => (
          <div key={card.id} className={`${card.type === 'stats' && showRadar ? getRowSpanClass(card.height) : 'row-span-1'} h-full`}>
            {card.type === 'stats' && showRadar ? (
              <div
                className={`bg-white shadow-lg border-l-4 border-indigo-500 rounded-lg overflow-hidden transition transform hover:scale-105 duration-300 h-full flex items-center justify-center ${getRowSpanClass(card.height)}`}
                draggable
                onDragStart={() => handleDragStart(card.id)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop(card.id)}
              >
                <RadarChart onClose={() => setShowRadar(false)} />
              </div>
            ) : card.type === 'tags' ? (
              <div
                className={`bg-white shadow-lg border-l-4 border-yellow-500 rounded-lg overflow-hidden transition transform hover:scale-105 duration-300 h-full`}
                draggable
                onDragStart={() => handleDragStart(card.id)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop(card.id)}
              >
                {renderTagSelection()}
              </div>
            ) : (
              <div
                className={`bg-white shadow-lg border-l-4 border-green-500 rounded-lg overflow-hidden transition transform hover:scale-105 duration-300 h-full`}
                draggable
                onDragStart={() => handleDragStart(card.id)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop(card.id)}
              >
                <Link to={card.type === 'link' ? card.link : '#'}>
                  <div className="px-4 py-3 h-full flex flex-col justify-between">
                    <h2 className="text-xl text-gray-900 font-bold mb-1 flex items-center">
                      <i className={`fa ${card.icon} mr-2`}></i> {/* Add the icon */}
                      {card.title}
                    </h2>
                    <p className="text-gray-700 text-sm">{card.description}</p>
                  </div>
                </Link>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ForUsers;
