import React, { useState } from 'react';
import api from '../../../utils/tokenUtils';
import { useNavigate } from 'react-router-dom';

const CreateClass = () => {
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(''); // State for success messages
    const navigate = useNavigate();

    const handleCreateClass = async (e) => {
        e.preventDefault();
        setError(''); // Reset error message
        setSuccess(''); // Reset success message
        try {
            await api.post('/class', { name });
            setSuccess('Class created successfully!'); // Set success message
            setTimeout(() => {
                navigate('/classes');
            }, 2000); // Navigate after a short delay
        } catch (error) {
            setError('Error creating class. Please try again.');
        }
    };

    return (
        <div className="min-h-screen bg-gray-900 text-gray-200 flex items-center justify-center py-10 px-6">
            <div className="bg-gray-800 p-10 rounded-lg shadow-lg max-w-lg w-full">
                <h2 className="text-3xl font-bold text-green-400 mb-6 text-center">Create a New Class</h2>
                {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
                {success && <p className="text-green-500 mb-4 text-center">{success}</p>}
                <form onSubmit={handleCreateClass} className="space-y-6">
                    <div>
                        <label htmlFor="name" className="block text-lg font-medium mb-2">Class Name</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded text-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-green-500 focus:outline-none transition-all"
                            required
                            placeholder="Enter class name"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-green-500 text-white py-3 rounded-md hover:bg-green-600 transition-colors"
                    >
                        Create Class
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CreateClass;
