import React from 'react';
import { renderImage, renderVideo } from '../../../../../utils/mediaUtils';

const Question = ({ question, image }) => (
    <div className="mb-6">
        <p className="mb-4">Zadanie: {question.text}</p>
        {image && image !== 'NULL' && (
            image.match(/\.(jpg|jpeg|png|gif)$/i) ? renderImage(image) : 
            image.match(/\.(mp4)$/i) ? renderVideo(image) : null
        )}
    </div>
);

export default Question;
