import React, { useState, useEffect } from 'react';

const Answers = ({ answers, selectedAnswer, handleAnswerSelect, result, answersDisabled }) => {
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);

  // Trigger animation when an answer is selected
  useEffect(() => {
    if (selectedAnswer) {
      setIsAnswerSelected(true);
      setTimeout(() => {
        setIsAnswerSelected(false); // Reset animation state after the animation is done
      }, 600); // Reset after animation duration (adjust if needed)
    }
  }, [selectedAnswer]);

  return (
    <div className="mb-6 space-y-2">
      {Object.entries(answers).map(([key, answer], index) => (
        <button
          key={index}
          type="button"
          onClick={() => handleAnswerSelect(key)}
          className={`block w-full text-left py-2 px-4 rounded-lg transition-all duration-300 ease-in-out transform
                      ${getButtonClass(key, result, selectedAnswer)}
                      ${isAnswerSelected ? 'animate-pulse' : ''}`}
          disabled={answersDisabled}
        >
          {answer}
        </button>
      ))}
    </div>
  );
};

const getButtonClass = (key, result, selectedAnswer) => {
  if (result) {
    if (selectedAnswer === key) {
      return result.correct ? 'bg-green-500 text-white' : 'bg-red-500 text-white';
    } else if (result.correct_answer === key) {
      return 'bg-green-500 text-white';
    }
  }
  return 'bg-gray-800 text-gray-200 hover:bg-gray-700';
};

export default Answers;
