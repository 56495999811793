import React, { useEffect, useState } from 'react';
import api from '../../../utils/tokenUtils';

const InviteToClass = () => {
    const [email, setEmail] = useState('');
    const [classId, setClassId] = useState('');
    const [classes, setClasses] = useState([]);
    const [message, setMessage] = useState('');

    // Fetch classes on component mount
    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const response = await api.get('/class');
                setClasses(response.data.classes);
            } catch (error) {
                console.error('Error fetching classes:', error);
            }
        };
        fetchClasses();
    }, []);

    const handleInvite = async (e) => {
        e.preventDefault();
        try {
            await api.post('/invite', { email, class_id: classId });
            setMessage('Invitation sent successfully');
            setEmail('');  // Clear input fields after successful invite
            setClassId('');
        } catch (error) {
            setMessage('Error sending invitation');
        }
    };

    return (
        <div className="min-h-screen bg-gray-900 text-gray-200 flex items-center justify-center py-10 px-6">
            <div className="bg-gray-800 p-10 rounded-lg shadow-lg max-w-lg w-full">
                <h2 className="text-3xl font-bold text-green-400 mb-6 text-center">Invite to Class</h2>
                {message && (
                    <p className={`mb-4 text-center ${message.includes('Error') ? 'text-red-500' : 'text-green-500'}`}>
                        {message}
                    </p>
                )}
                <form onSubmit={handleInvite} className="space-y-6">
                    <div>
                        <label htmlFor="email" className="block text-lg font-medium mb-2">Student Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded text-gray-300 focus:ring-2 focus:ring-green-500 focus:outline-none transition-all"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="classId" className="block text-lg font-medium mb-2">Class</label>
                        <select
                            id="classId"
                            value={classId}
                            onChange={(e) => setClassId(e.target.value)}
                            className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded text-gray-300 focus:ring-2 focus:ring-green-500 focus:outline-none transition-all"
                            required
                        >
                            <option value="" disabled>Select a class</option>
                            {classes.map((classItem) => (
                                <option key={classItem.class_id} value={classItem.class_id}>
                                    {classItem.class_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-green-500 text-white py-3 rounded-md hover:bg-green-600 transition-colors"
                    >
                        Send Invitation
                    </button>
                </form>
            </div>
        </div>
    );
};

export default InviteToClass;
