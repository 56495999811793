import React, { useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import api from '../../../utils/tokenUtils';

const AcceptInvitation = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);

    const className = searchParams.get('class');
    const teacherName = searchParams.get('teacher');

    const acceptInvite = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/accept-invitation/${token}`);
            setMessage(response.data.message);
            setLoading(false);
        } catch (error) {
            setMessage("Error accepting the invitation.");
            setError(true);
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-br from-purple-600 via-blue-500 to-indigo-600 p-6">
            {loading ? (
                <p className="text-2xl font-semibold text-white animate-pulse">Accepting your invitation...</p>
            ) : error ? (
                <p className="text-2xl text-red-600 font-bold">{message}</p>
            ) : (
                <div className="bg-white shadow-xl rounded-xl p-8 md:p-12 text-center transform transition-all duration-500 hover:scale-105 hover:shadow-2xl">
                    <h2 className="text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-teal-500 to-cyan-500 mb-6">
                        You've been invited!
                    </h2>
                    <p className="text-lg text-gray-700 mb-4">
                        You have been invited to join <strong>{className}</strong> by <strong>{teacherName}</strong>.
                    </p>
                    <p className="text-md text-gray-500 mb-8">Click the button below to accept the invitation.</p>
                    <button 
                        onClick={acceptInvite} 
                        className="bg-gradient-to-r from-green-400 to-teal-500 hover:from-teal-500 hover:to-cyan-600 text-white py-3 px-6 rounded-lg text-xl font-bold shadow-lg transform transition duration-300 hover:scale-110 hover:shadow-2xl focus:outline-none focus:ring-4 focus:ring-teal-300"
                    >
                        Accept Invitation
                    </button>
                    {message && <p className="text-lg text-gray-700 mt-4">{message}</p>}
                </div>
            )}
        </div>
    );
};

export default AcceptInvitation;
