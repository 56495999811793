import React, { useEffect, useState } from 'react';
import api from '../../../../utils/tokenUtils';
import Swal from 'sweetalert2';
import Modal from 'react-modal';
import MDEditor from '@uiw/react-md-editor';

Modal.setAppElement('#root'); // For accessibility

const Mapper = () => {
    const [data, setData] = useState(null);
    const [image, setImage] = useState(null);
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [result, setResult] = useState(null);
    const [stats, setStats] = useState(null);
    const [loadingStats, setLoadingStats] = useState(false);
    const [answersDisabled, setAnswersDisabled] = useState(false);
    const [guides, setGuides] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedGuide, setSelectedGuide] = useState(null);
    const [loadingGuide, setLoadingGuide] = useState(false);
    const [showCreateGuideButton, setShowCreateGuideButton] = useState(false);
    const [questionIdForGuide, setQuestionIdForGuide] = useState(null);
    const [questionId, setQuestionId] = useState(1);
    const [inputValue, setInputValue] = useState(''); // State for input
    const [tags, setTags] = useState([]);


    useEffect(() => {
        fetchQuestion();
        fetchTags();
    }, [questionId]);

    const fetchTags = async () => {
        try {
            const response = await api.post('/tags_list/3');
            // Convert the array of arrays into an array of objects
            const tagData = response.data.tags.map(([tag_id, name]) => ({ tag_id, name }));
            setTags(tagData);
        } catch (error) {
            console.error('Failed to fetch tags', error);
        }
    };
    

    const handleTagClick = async (tagId) => {
        try {
            await api.post('/set_question_tag', {
                question_id: questionId,
                tag_id: tagId
            });
            // Swal.fire({
            //     title: 'Sukces!',
            //     text: 'Tag został przypisany do pytania.',
            //     icon: 'success',
            //     confirmButtonText: 'OK'
            // });
        } catch (error) {
            console.error('Failed to set question tag', error);
            Swal.fire({
                title: 'Błąd',
                text: 'Nie udało się przypisać tagu do pytania.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };
    
    

    const fetchQuestion = async () => {
        try {
            const response = await api.post('/get_questions', {
                "exam": "2",
                "type": "one_exact_question",
                // "tag": "JavaScript",
                "question_id": questionId
            });
            setData(response.data);
            setImage(response.data.question.image);
            setResult(null);
            setSelectedAnswer('');
            setAnswersDisabled(false);
            setShowCreateGuideButton(false);
            setQuestionIdForGuide(null);
            // Fetch guides for the new question
            fetchGuides(response.data.question.question_id);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                Swal.fire({
                    title: 'Ostrzeżenie!',
                    text: 'Odpowiedziałeś na 100 pytań w ciągu minuty. Jesteś albo geniuszem, albo powinieneś zostać zablokowany.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                console.error('Nie udało się pobrać pytania', error);
            }
        }
    };

    const fetchGuides = async (questionId) => {
        try {
            const response = await api.post(`/guide/question/${questionId}`);
            const fetchedGuides = response.data.data;
            setGuides(fetchedGuides);
        } catch (error) {
            setGuides([]);
        }
    };

    const fetchGuideDetails = async (guideId) => {
        setLoadingGuide(true);
        try {
            const response = await api.post(`/guide/id/${guideId}`);
            setSelectedGuide(response.data);
        } catch (error) {
            console.error('Nie udało się pobrać szczegółów poradnika', error);
        } finally {
            setLoadingGuide(false);
        }
    };

    const handleAnswerSelect = async (answerKey) => {
        if (answersDisabled) return;
        setSelectedAnswer(answerKey);
        setAnswersDisabled(true);
        try {
            const response = await api.post('/check_answers', {
                answers: [
                    {
                        question_id: data.question.question_id,
                        answer: answerKey
                    }
                ]
            });
            const answerResult = response.data.data[0];
            setResult(answerResult);

            // Show button to create guide if the answer is correct
            if (answerResult.correct) {
                setShowCreateGuideButton(true);
                setQuestionIdForGuide(data.question.question_id);
            } else {
                setShowCreateGuideButton(false);
                setQuestionIdForGuide(null);
            }
        } catch (error) {
            console.error('Nie udało się sprawdzić odpowiedzi', error);
        }
    };

    const getButtonClass = (key) => {
        if (result) {
            if (selectedAnswer === key) {
                return result.correct ? 'bg-green-500 text-white' : 'bg-red-500 text-white';
            } else if (result.correct_answer === key) {
                return 'bg-green-500 text-white';
            }
        }
        return 'bg-gray-800 text-gray-200 hover:bg-gray-700';
    };

    const fetchStats = async () => {
        setLoadingStats(true);
        try {
            const response = await api.post('/get_answers');
            const answers = response.data.answers;
            const total = answers.length;
            const correctCount = answers.filter(answer => answer.split(',')[3] === 'True').length;
            const percentage = total > 0 ? (correctCount / total) * 100 : 0;
            setStats({ percentage, total, correctCount });

            Swal.fire({
                title: 'Statystyki',
                html: `
                    <p>Łączna liczba odpowiedzi: ${total}</p>
                    <p>Liczba poprawnych odpowiedzi: ${correctCount}</p>
                    <p>Procent poprawnych odpowiedzi: ${percentage.toFixed(2)}%</p>
                `,
                icon: 'info',
                confirmButtonText: 'OK'
            });
        } catch (error) {
            console.error('Nie udało się pobrać statystyk', error);
            Swal.fire({
                title: 'Błąd',
                text: 'Nie udało się pobrać statystyk',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } finally {
            setLoadingStats(false);
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedGuide(null); // Reset the selected guide when the modal closes
    };

    const handleGuideSelect = async (guide) => {
        await fetchGuideDetails(guide.id); // Fetch guide details before setting the selected guide
    };

    const nextQuestion = () => {
        setQuestionId(prevQuestionId => prevQuestionId + 1);
    };

    const previousQuestion = () => {
        setQuestionId(prevQuestionId => Math.max(prevQuestionId - 1, 1)); // Prevent going below 1
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleInputSubmit = () => {
        const newQuestionId = parseInt(inputValue);
        if (!isNaN(newQuestionId)) {
            setQuestionId(newQuestionId);
        }
    };


    return (
        <div className="bg-gray-900 text-gray-100 min-h-screen p-6">
            {data ? (
                <div>
                    <div className="space-x-5 pb-2">
                        <button onClick={() => previousQuestion()} className='bg-slate-500 px-2 rounded-xl'><i class="fa-solid fa-arrow-left"></i></button>
                        <input
                            type="number"
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={(e) => { if (e.key === 'Enter') handleInputSubmit() }}
                            className="bg-gray-700 text-white px-2 py-1 rounded-lg w-20 text-center"
                            placeholder="ID"
                        />
                        <button onClick={() => nextQuestion()} className='bg-slate-500 px-2 rounded-xl'><i class="fa-solid fa-arrow-right"></i></button>
                    </div>
                    <div className="mb-6">
                        <p className="mb-4">Zadanie: {data.question.text}</p>
                        {image && image !== 'https://egzamin-inf.pl/images/egzaminy/NULL' && (
                            <img
                                src={image}
                                alt="Pytanie"
                                className="w-auto h-auto rounded-lg max-w-full max-h-80 object-cover"
                            />
                        )}
                    </div>

                    <div className="mb-6">
                        {Object.entries(data.question.answers).map(([key, answer], index) => (
                            <button
                                key={index}
                                type="button"
                                onClick={() => handleAnswerSelect(key)}
                                className={`block w-full text-left py-2 px-4 mb-2 rounded-lg ${getButtonClass(key)}`}
                                disabled={answersDisabled}
                            >
                                {answer}
                            </button>
                        ))}
                    </div>

                    <div className="flex space-x-4 mb-4">
                        <button
                            onClick={fetchQuestion}
                            className="bg-blue-500 text-white flex-grow py-2 px-4 rounded hover:bg-blue-600"
                        >
                            Pobierz nowe pytanie
                        </button>
                        {guides.length > 0 && (
                            <button
                                onClick={openModal}
                                className="bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600"
                            >
                                <i className="fa-solid fa-book"></i>
                            </button>
                        )}
                        {/* Conditionally render the guide creation button */}
                        {showCreateGuideButton && questionIdForGuide && (
                            <a
                                href={`/create-guide/${questionIdForGuide}`}
                                className="bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600"
                            >
                                <i class="fa-solid fa-pen"></i>
                            </a>
                        )}
                        <button
                            onClick={fetchStats}
                            className="bg-emerald-500 text-white py-2 px-4 rounded hover:bg-emerald-600 flex items-center space-x-2"
                        >
                            <i className="fa-solid fa-chart-column"></i>
                            {/* <span>Statystyki</span> */}
                        </button>
                    </div>

                    <div className="mb-6">
                        <p className="mb-4">Tagi:</p>
                        <div className="flex flex-wrap gap-2">
                            {tags.map(tag => (
                                <button
                                    key={tag.name}
                                    onClick={() => handleTagClick(tag.tag_id)}
                                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                                >
                                    {tag.name}
                                </button>
                            ))}
                        </div>
                    </div>

                </div>


            ) : (
                <p className="text-center italic mt-4">Ładowanie...</p>
            )}

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Poradniki"
                className="modal bg-white p-6 rounded-lg shadow-lg max-w-2xl mx-auto mt-16"
                overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-50"
            >
                <div className="modal-content">
                    <h2 className="text-2xl font-bold mb-4">Poradniki</h2>
                    {selectedGuide ? (
                        <div>
                            <h3 className="text-xl font-semibold">{selectedGuide.title}</h3>
                            <p className="text-sm text-gray-500 mb-4">Stworzony przez użytkownika: {selectedGuide.user_id}</p>
                            <p className="text-sm text-gray-500 mb-4">Data utworzenia: {new Date(selectedGuide.creation_date).toLocaleDateString()}</p>
                            <div className="guide-text mt-4 overflow-auto max-h-96">
                                {loadingGuide ? (
                                    <p>Ładowanie...</p>
                                ) : (
                                    <MDEditor.Markdown source={selectedGuide.text} />
                                )}
                            </div>
                            <button
                                onClick={() => setSelectedGuide(null)}
                                className="mt-6 bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
                            >
                                Powrót do listy
                            </button>
                        </div>
                    ) : (
                        <div>
                            <ul>
                                {guides.map((guide) => (
                                    <li key={guide.id} className="mb-2">
                                        <button
                                            onClick={async () => {
                                                await handleGuideSelect(guide);
                                                setIsModalOpen(true);
                                            }}
                                            className="w-full bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600"
                                        >
                                            {guide.title}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <button
                                onClick={closeModal}
                                className="mt-4 bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
                            >
                                Zamknij
                            </button>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default Mapper;
