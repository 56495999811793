import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getQuestions, checkAnswer, getGuidesByQuestion } from '../../../../utils/api';
import Swal from 'sweetalert2';
import Question from './Question/Question';
import Answers from './Answers/Answers';
import GuidesModal from './GuidesModal/GuidesModal';
import Stats from './Stats/Stats';
import { renderImage, renderVideo } from '../../../../utils/mediaUtils';
import './TestOne.module.css';

const TestOne = () => {
    const [data, setData] = useState(null);
    const [image, setImage] = useState(null);
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [result, setResult] = useState(null);
    const [answersDisabled, setAnswersDisabled] = useState(false);
    const [guides, setGuides] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [lastClickTime, setLastClickTime] = useState(0);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        fetchQuestion();
    }, [searchParams]);

    const fetchQuestion = async () => {
        try {
            const examParam = searchParams.get('exam');
            const questId = searchParams.get('id');
            const tagsParam = searchParams.getAll('tags');
            const examType = searchParams.get('type');
            const questionData = await getQuestions(examParam, tagsParam, examType, questId);
            setData(questionData);
            setImage(questionData.question.image);
            setResult(null);
            setSelectedAnswer('');
            setAnswersDisabled(false);

            try {
                await fetchGuides(questionData.question.question_id);
            } catch (error) {
                // If fetchGuides fails with 404, log the error to the console
                if (error.response && error.response.status === 404) {
                    
                } else {
                    throw error; // Re-throw the error if it's not 404, to handle in the outer catch block
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 429) {
                Swal.fire({
                    title: 'Ostrzeżenie!',
                    text: 'Za dużo odpowiedzi w krótkim czasie. Zwolnij!',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            } else {
                console.error('Nie udało się pobrać pytania', error);
            }
        }
    };

    const handleNewQuestionClick = () => {
        const currentTime = Date.now();
        if (currentTime - lastClickTime >= 200) {
            fetchQuestion();
            setLastClickTime(currentTime);
        } else {
            Swal.fire({
                title: 'Zbyt szybkie kliknięcie!',
                text: 'Ograniczenie do 2 kliknięć na sekundę.',
                icon: 'warning',
                confirmButtonText: 'OK',
            });
        }
    };

    const fetchGuides = async (questionId) => {
        const fetchedGuides = await getGuidesByQuestion(questionId);
        setGuides(fetchedGuides);
    };

    const handleAnswerSelect = async (answerKey) => {
        if (answersDisabled) return;

        setSelectedAnswer(answerKey);
        setAnswersDisabled(true);

        try {
            const answerResult = await checkAnswer(data.question.question_id, answerKey);
            setResult(answerResult.data[0]);

            // Reset modal and guide creation state
            if (answerResult.data[0].correct) {
                // handle correct answer logic
            }
        } catch (error) {
            console.error('Nie udało się sprawdzić odpowiedzi', error);
        }
    };

    return (
        <div className="bg-gray-900 text-gray-100 min-h-screen p-6">
            {data ? (
                <div>
                    <Question question={data.question} image={image} />
                    <Answers
                        answers={data.question.answers}
                        selectedAnswer={selectedAnswer}
                        handleAnswerSelect={handleAnswerSelect}
                        result={result}
                        answersDisabled={answersDisabled}
                    />
                    <div className="flex space-x-4 mb-4">
                        <button
                            onClick={handleNewQuestionClick}
                            className="bg-blue-500 text-white flex-grow py-2 px-4 rounded hover:bg-blue-600"
                        >
                            Nowe pytanie
                        </button>
                        <Stats/>
                    </div>
                </div>
            ) : (
                <p className="text-center italic mt-4">Ładowanie...</p>
            )}
        </div>
    );
};

export default TestOne;
