import React from 'react';
// import { Link } from 'react-router-dom';
import './Main.css'; // Import the CSS file

const ForGuests = () => {
  return (
    <>
      <main id='hero-section' className="grid min-h-full bg-gray-800 place-items-center px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center  py-10 rounded-3xl ">
          <h1 className="text-4xl font-bold tracking-tight text-gray-100 sm:text-5xl">Witaj na naszej stronie!</h1>
          <p className="mt-4 text-lg text-gray-100">
            Nasza strona oferuje szereg testów przygotowanych z myślą o Twoim rozwoju.
            Przede wszystkim możesz przetestować swoją wiedzę w różnych dziedzinach, takich jak JavaScript i inne technologie.
          </p>
          <div className="mt-10 flex justify-center">
            <a href="#info"
              className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
            >Dowiedz się więcej</a>
          </div>
        </div>
      </main>

      {/* Exam Section */}
      <div className="bg-indigo-50 py-20" id="info">
        <h1 className="text-4xl font-extrabold text-center mb-10">Egzaminy, do których możesz się przygotować:</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-6 lg:px-32">
          {/* Card 1: INF02 */}
          <div className="bg-white shadow-md rounded-lg overflow-hidden transition transform hover:scale-105 duration-300">
            <img src="https://egzamin-inf.pl/inf02.png" alt="Informatyka systemy operacyjne i sieci komputerowe" className="w-full h-72 object-cover" />
            <div className="p-6">
              <h2 className="text-2xl text-gray-900 font-bold mb-2">Kwalifikacja INF02</h2>
              <p className="text-gray-700">Informatyka systemy operacyjne i sieci komputerowe. Przygotuj się do pracy z systemami operacyjnymi oraz konfiguracją sieci komputerowych.</p>
            </div>
          </div>

          {/* Card 2: INF03 */}
          <div className="bg-white shadow-md rounded-lg overflow-hidden transition transform hover:scale-105 duration-300">
            <img src="https://egzamin-inf.pl/inf03.png" alt="Tworzenie i administrowanie stronami i aplikacjami" className="w-full h-72 object-cover" />
            <div className="p-6">
              <h2 className="text-2xl text-gray-900 font-bold mb-2">Kwalifikacja INF03</h2>
              <p className="text-gray-700">Tworzenie i administrowanie stronami i aplikacjami internetowymi oraz bazami danych. Zdobądź wiedzę w zakresie tworzenia aplikacji webowych.</p>
            </div>
          </div>

          {/* Card 3: INF04 */}
          <div className="bg-white shadow-md rounded-lg overflow-hidden transition transform hover:scale-105 duration-300">
            <img src="https://egzamin-inf.pl/inf04.png" alt="Projektowanie, programowanie i testowanie aplikacji" className="w-full h-72 object-cover" />
            <div className="p-6">
              <h2 className="text-2xl text-gray-900 font-bold mb-2">Kwalifikacja INF04</h2>
              <p className="text-gray-700">Projektowanie, programowanie i testowanie aplikacji. Naucz się technik projektowania i testowania oprogramowania oraz kodowania.</p>
            </div>
          </div>
        </div>
      </div>

      {/* New Section: Site Features */}
      <div className="bg-white py-20 px-6 lg:px-32">
        <h1 className="text-4xl font-extrabold text-center mb-10">Możliwości naszej strony:</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          <div className="bg-gray-50 shadow-md rounded-lg p-8 transition transform hover:scale-105 duration-300">
            <h2 className="text-2xl font-bold mb-4 text-indigo-600">Przygotowanie do egzaminów</h2>
            <p className="text-gray-700">Uzyskaj dostęp do testów oraz materiałów, które pomogą Ci skutecznie przygotować się do egzaminów zawodowych.</p>
          </div>
          <div className="bg-gray-50 shadow-md rounded-lg p-8 transition transform hover:scale-105 duration-300">
            <h2 className="text-2xl font-bold mb-4 text-indigo-600">Statystyki odpowiedzi</h2>
            <p className="text-gray-700">Monitoruj swoje wyniki na bieżąco, analizuj błędy i śledź postępy w nauce, aby doskonalić swoje umiejętności.</p>
          </div>

          <div className="bg-gray-50 shadow-md rounded-lg p-8 transition transform hover:scale-105 duration-300">
            <h2 className="text-2xl font-bold mb-4 text-indigo-600">Poradniki do pytań</h2>
            <p className="text-gray-700">Zyskuj dostęp do szczegółowych poradników i wskazówek, które pomogą Ci lepiej zrozumieć każde pytanie egzaminacyjne.</p>
          </div>

          <div className="bg-gray-50 shadow-md rounded-lg p-8 transition transform hover:scale-105 duration-300">
            <h2 className="text-2xl font-bold mb-4 text-indigo-600">Historia testów</h2>
            <p className="text-gray-700">Przeglądaj wcześniej wykonane testy, sprawdzaj odpowiedzi i analizuj postępy w czasie.</p>
          </div>

          <div className="bg-gray-50 shadow-md rounded-lg p-8 transition transform hover:scale-105 duration-300">
            <h2 className="text-2xl font-bold mb-4 text-indigo-600">Testy INF.02, INF.03, INF.04</h2>
            <p className="text-gray-700">Rozwiązuj testy związane z kwalifikacjami INF02, INF03 oraz INF04 i przygotuj się do egzaminów zawodowych.</p>
          </div>

          <div className="bg-gray-50 shadow-md rounded-lg p-8 transition transform hover:scale-105 duration-300">
            <h2 className="text-2xl font-bold mb-4 text-indigo-600">Funkcje dla nauczycieli</h2>
            <p className="text-gray-700">Twórz klasy, dodawaj uczniów i monitoruj ich postępy. Wyznaczaj zadania i analizuj wyniki testów swoich uczniów.</p>
          </div>


        </div>
      </div>

      {/* Accordion FAQ */}
      <div className="bg-indigo-50 py-20 px-6 lg:px-32">
        <h1 className="text-4xl font-extrabold text-center mb-10">Często zadawane pytania</h1>
        <div className="max-w-4xl mx-auto">
          <details className="bg-white shadow-md rounded-lg p-6 mb-4">
            <summary className="text-xl font-bold text-indigo-600 cursor-pointer">Jak mogę przystąpić do testów?</summary>
            <p className="mt-4 text-gray-700">Aby przystąpić do testów, musisz się zarejestrować na naszej stronie i wybrać interesujący Cię egzamin z dostępnych opcji.</p>
          </details>

          <details className="bg-white shadow-md rounded-lg p-6 mb-4">
            <summary className="text-xl font-bold text-indigo-600 cursor-pointer">Czy mogę przeglądać swoje wyniki?</summary>
            <p className="mt-4 text-gray-700">Tak, w każdej chwili możesz przejrzeć wyniki wcześniejszych testów, aby zobaczyć, jakie postępy poczyniłeś.</p>
          </details>

          <details className="bg-white shadow-md rounded-lg p-6 mb-4">
            <summary className="text-xl font-bold text-indigo-600 cursor-pointer">Jak nauczyciele mogą monitorować uczniów?</summary>
            <p className="mt-4 text-gray-700">Nauczyciele mogą tworzyć klasy, dodawać uczniów i śledzić ich postępy w ramach dedykowanych funkcji dla nauczycieli.</p>
          </details>

          <details className="bg-white shadow-md rounded-lg p-6 mb-4">
            <summary className="text-xl font-bold text-indigo-600 cursor-pointer">Czy mogę podejść do egzaminów więcej niż raz?</summary>
            <p className="mt-4 text-gray-700">Tak, możesz podejść do egzaminów wielokrotnie, aby lepiej się przygotować do prawdziwego egzaminu.</p>
          </details>
        </div>
      </div>
    </>
  );
};

export default ForGuests;