// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Parts/Header/Header';
import Login from './components/Pages/Auth/Login/Login';
import Register from './components/Pages/Auth/Register/Register';
import TestOne from './components/Pages/Tests/TestOne/TestOne';
import FullTest from './components/Pages/Tests/TestForty/FullTest';
import MainPage from './components/Pages/MainPage/MainPage';
import NotFoundPage from './components/Pages/Errors/404/NotFoundPage';
import CreateGuide from './components/Pages/Guides/CreateGuide/CreateGuide';
import GuideViewer from './components/Pages/Guides/GuideViewer/GuideViewer';
import VerifyEmail from './components/Pages/Auth/VerifyEmail/VerifyEmail';
import Mapper from './components/Pages/Utils/Mapper/Mapper';
import PasswordResetRequest from './components/Pages/Auth/PasswordReset/PasswordResetRequest';
import PasswordReset from './components/Pages/Auth/PasswordReset/PasswordReset';
import UserProfile from './components/Pages/ProfilePage/UserProfile';
import AdminPage from './components/Pages/AdminPage/AdminPage';
import CreateClass from './components/Pages/Classes/CreateClass';
import ClassList from './components/Pages/Classes/ClassList';
import InviteToClass from './components/Pages/Classes/InviteToClass';
import AcceptInvitation from './components/Pages/AcceptInvitation/AcceptInvitation';
import ClassDetail from './components/Pages/Classes/ClassDetail';
import TasksList from './components/Pages/Tasks/TaskList';
import MarkdownViewer from './components/Pages/MarkdownViewer/MarkdownViewer';

function App() {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/test" element={<TestOne />} />
                <Route path="/full-test" element={<FullTest />} />
                <Route path="/create-guide/:id?" element={<CreateGuide />} />
                <Route path="/guide/id/:id" element={<GuideViewer />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/mapper" element={<Mapper />} />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/password-reset-request" element={<PasswordResetRequest />} />
                <Route path="/password-reset/:token" element={<PasswordReset />} />
                <Route path="/profile/:id" element={<UserProfile />} />
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/accept-invitation/:token" element={<AcceptInvitation />} />
                <Route path="/classes" element={<ClassList />} />
                <Route path="/classes/:class_id" element={<ClassDetail />} />
                <Route path="/create-class" element={<CreateClass />} />
                <Route path="/invite-to-class" element={<InviteToClass />} />
                <Route path="/tasks-list" element={<TasksList />}/>
                <Route path="/markdown-viewer" element={<MarkdownViewer />} />
            </Routes>
        </Router>
    );
}

export default App;
