// src/utils/tokenUtils.js
import axios from 'axios';

// Token management helper functions
export const setTokens = (accessToken, refreshToken) => {
  localStorage.setItem('access_token', accessToken);
  localStorage.setItem('refresh_token', refreshToken);
};

export const getAccessToken = () => localStorage.getItem('access_token');
export const getRefreshToken = () => localStorage.getItem('refresh_token');

export const clearTokens = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
};

// Function to decode JWT and extract user ID
const decodeJWT = (token) => {
  if (!token) {
    return null;
  }

  const base64Url = token.split('.')[1]; // Get the payload part of the JWT
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Handle URL-safe base64 encoding
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload); // Return the decoded payload as a JSON object
};

// Function to get user ID from the access token
export const getUserIdFromToken = () => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return null;
  }

  const decoded = decodeJWT(accessToken);
  return decoded ? decoded.user_id : null; // Assuming the user ID is stored in the 'user_id' field
};

// Axios instance for making API calls with automatic token refreshing
const api = axios.create({
  baseURL: 'https://apii.egzamin-inf.pl/',
});

api.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = getRefreshToken();
      try {
        const response = await axios.post(
          'https://apii.egzamin-inf.pl/refresh',
          {},
          {
            headers: { Authorization: `Bearer ${refreshToken}` },
          }
        );
        const newAccessToken = response.data.access_token;
        setTokens(newAccessToken, refreshToken);
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return api(originalRequest); // Retry the original request with the new token
      } catch (err) {
        clearTokens();
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default api;
