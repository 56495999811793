import React, { useState } from 'react';
import api from '../../../../utils/tokenUtils';
import { useNavigate } from 'react-router-dom';
import { setTokens } from '../../../../utils/tokenUtils';
import { FaUserGraduate, FaChalkboardTeacher } from 'react-icons/fa';

const Register = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [accountType, setAccountType] = useState('student');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!accountType) {
      setError('Please select account type (Student or Teacher)');
      return;
    }
    try {
      const response = await api.post('/register', {
        email,
        username,
        password,
        account_type: accountType,
      });
      setTokens(response.data.access_token, response.data.refresh_token);
      navigate('/verify-email');
    } catch (err) {
      setError(err.response.data.message || 'Registration failed');
    }
  };

  const handleAccountTypeSelection = (type) => {
    setAccountType(type);
    setError(''); // Clear error if any
  };

  return (
    <div className="bg-gray-900 text-gray-200 min-h-screen flex items-center justify-center p-6">
      <div className="bg-gray-800 p-10 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-3xl font-bold mb-8 text-center">Register</h2>
        <form onSubmit={handleRegister}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="block w-full p-3 mb-4 bg-gray-700 border border-gray-600 rounded text-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-green-500 focus:outline-none transition-all"
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="block w-full p-3 mb-4 bg-gray-700 border border-gray-600 rounded text-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-green-500 focus:outline-none transition-all"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="block w-full p-3 mb-6 bg-gray-700 border border-gray-600 rounded text-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-green-500 focus:outline-none transition-all"
          />
          <div className="flex justify-between mb-6">
            <button
              type="button"
              onClick={() => handleAccountTypeSelection('student')}
              className={`flex items-center justify-center w-1/2 p-4 mr-2 text-lg font-bold rounded-lg border transition-colors duration-200 ${accountType === 'student' ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'}`}
            >
              <FaUserGraduate className="mr-2" />
              Student
            </button>
            <button
              type="button"
              onClick={() => handleAccountTypeSelection('teacher')}
              className={`flex items-center justify-center w-1/2 p-4 ml-2 text-lg font-bold rounded-lg border transition-colors duration-200 ${accountType === 'teacher' ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'}`}
            >
              <FaChalkboardTeacher className="mr-2" />
              Teacher
            </button>
          </div>
          <button
            type="submit"
            className="w-full bg-green-500 text-white py-3 rounded-lg hover:bg-green-600 transition-colors duration-200"
          >
            Register
          </button>
          {error && <p className="mt-4 text-red-500">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default Register;
