import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import MDEditor from '@uiw/react-md-editor';

const MarkdownViewer = () => {
    const [content, setContent] = useState('');
    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const fileName = query.get('file');

        if (fileName) {
            fetch(`${fileName}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('File not found');
                    }
                    return response.text();
                })
                .then(text => setContent(text))
                .catch(err => console.error(err));
        }
    }, [location]);

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Markdown Viewer</h1>
            <MDEditor.Markdown source={content}></MDEditor.Markdown>
        </div>
    );
};

export default MarkdownViewer;
