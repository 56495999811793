export const renderImage = (imageUrl) => (
    <div>
        <img src={imageUrl} alt="question media" className="max-w-full h-auto rounded-md" />
    </div>
);

export const renderVideo = (videoUrl) => (
    <video controls className="max-w-full h-auto mx-auto">
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
    </video>
);
