import React from 'react';
import { Link } from 'react-router-dom';  // Добавим ссылку на возможность повторной отправки письма
import { FiMail } from 'react-icons/fi';  // Используем иконку почты

const VerifyEmail = () => {
  return (
    <div className="bg-gradient-to-r from-blue-500 to-indigo-600 min-h-screen flex items-center justify-center p-6">
      <div className="bg-white p-8 rounded-xl shadow-lg max-w-md w-full text-center transition-transform transform hover:scale-105 duration-300">
        <div className="text-blue-500 flex justify-center mb-4">
          <FiMail size={48} />
        </div>
        <h2 className="text-3xl font-bold mb-4 text-gray-800">Verify Your Email</h2>
        <p className="mb-6 text-gray-600">Thank you for registering! A verification link has been sent to your email. Please check your inbox and follow the instructions to complete the registration process.</p>
        
        <Link to="/resend-verification" className="text-blue-600 hover:underline">
          Didn't receive the email? Resend
        </Link>
      </div>
    </div>
  );
};

export default VerifyEmail;
