import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../utils/tokenUtils';
import { FaEdit, FaSave, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';

const ClassList = () => {
    const [classes, setClasses] = useState([]);
    const [editingClass, setEditingClass] = useState(null);
    const [newClassName, setNewClassName] = useState('');

    useEffect(() => {
        async function fetchClasses() {
            try {
                const response = await api.get('/class');
                setClasses(response.data.classes);
            } catch (error) {
                console.error('Error fetching classes:', error);
            }
        }
        fetchClasses();
    }, []);

    const handleUpdateClass = async (classId) => {
        try {
            const response = await api.patch(`/class/${classId}`, { name: newClassName });
            setClasses((prevClasses) =>
                prevClasses.map((cls) => (cls.class_id === classId ? { ...cls, class_name: newClassName } : cls))
            );
            setEditingClass(null);
        } catch (error) {
            console.error('Error updating class name:', error);
        }
    };

    const handleDeleteClass = async (classId) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            try {
                await api.delete(`/class/${classId}`);
                setClasses((prevClasses) => prevClasses.filter((cls) => cls.class_id !== classId));
                Swal.fire('Deleted!', 'Your class has been deleted.', 'success');
            } catch (error) {
                console.error('Error deleting class:', error);
                Swal.fire('Error!', 'There was an error deleting the class.', 'error');
            }
        }
    };

    return (
        <div className="min-h-screen bg-gray-900 text-gray-200 flex flex-col items-center py-10 px-4">
            <h2 className="text-4xl font-bold mb-8 text-green-400">Manage Classes:</h2>
            {classes.length === 0 ? (
                <p className="text-lg">No classes available.</p>
            ) : (
                <ul className="w-full max-w-4xl space-y-6">
                    {classes.map((classItem) => (
                        <li key={classItem.class_id} className="bg-gray-800 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                            <div className="flex justify-between items-center">
                                {editingClass === classItem.class_id ? (
                                    <div className="flex w-full items-center">
                                        <input
                                            type="text"
                                            className="flex-grow p-3 bg-gray-700 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 transition-all"
                                            value={newClassName}
                                            onChange={(e) => setNewClassName(e.target.value)}
                                        />
                                        <button
                                            onClick={() => handleUpdateClass(classItem.class_id)}
                                            className="ml-4 bg-green-500 text-white p-3 rounded-lg hover:bg-green-600 transition-all"
                                        >
                                            <FaSave className="inline mx-1" />
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <Link to={`/classes/${classItem.class_id}`}>
                                            <h3 className="text-xl font-semibold hover:text-green-400 transition-all">
                                                {classItem.class_name}
                                            </h3>
                                        </Link>
                                        <div className="flex space-x-4">
                                            <button
                                                onClick={() => {
                                                    setEditingClass(classItem.class_id);
                                                    setNewClassName(classItem.class_name);
                                                }}
                                                className="bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600 transition-all"
                                            >
                                                <FaEdit className="inline mx-1" />
                                            </button>
                                            <button
                                                onClick={() => handleDeleteClass(classItem.class_id)}
                                                className="bg-red-500 text-white p-3 rounded-lg hover:bg-red-600 transition-all"
                                            >
                                                <FaTrash className="inline mx-1" />
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <p className="mt-4 text-gray-400">
                                {classItem.students.length === 0
                                    ? 'No students in this class.'
                                    : `${classItem.students.length} student(s) in this class.`}
                            </p>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ClassList;
