import React from 'react';
import ForGuests from './ForGuests/ForGuests';
import ForUsers from './ForUsers/ForUsers';
import { getAccessToken } from '../../../utils/tokenUtils';

// MainPage Component
const MainPage = () => {
  const accessToken = getAccessToken(); // Retrieve the access token from localStorage

  return (
    <>
      {accessToken ? <ForUsers /> : <ForGuests />}
    </>
  );
};

export default MainPage;