import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import api from '../../../../utils/tokenUtils';
import Swal from 'sweetalert2';

const FullTest = () => {
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState({});
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [results, setResults] = useState([]);
    const [correctCount, setCorrectCount] = useState(0);
    const [incorrectCount, setIncorrectCount] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [imageSizes, setImageSizes] = useState({});

    const [searchParams] = useSearchParams();

    useEffect(() => {
        fetchQuestions();
    }, [searchParams]);

    const fetchQuestions = async () => {
        try {
            const type_param = searchParams.get('type');
            const token_data = searchParams.get('token');

            let type_text = "";
            if (type_param == 2){
                type_text = "full_task_test";
            } else {
                type_text = "full_random_test";
            }

            const response = await api.post('/get_questions', {
                "exam": "2",
                "type": type_text,
                "task_token": token_data
            });
            setQuestions(response.data.question);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch questions', error);
            Swal.fire({
                title: 'Error',
                text: 'Failed to fetch questions',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const handleAnswerChange = (questionId, answerKey) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: answerKey
        }));
    };

    const handleSubmit = async () => {
        try {
            const token_data = searchParams.get('token');

            const answerArray = Object.keys(answers).map(questionId => ({
                question_id: Number(questionId),
                answer: answers[questionId]
            }));

            const response = await api.post('/check_answers', { 
                answers: answerArray,
                "task_token": token_data 
            });
            const resultData = response.data.data || [];

            setResults(resultData);

            const correctAnswers = resultData.filter(result => result.correct).length;
            const incorrectAnswers = 40 - correctAnswers;
            const successPercentage = (correctAnswers / 40) * 100;

            setCorrectCount(correctAnswers);
            setIncorrectCount(incorrectAnswers);
            setPercentage(successPercentage);

            setSubmitted(true);

            Swal.fire({
                title: 'Success',
                text: 'Your answers have been submitted successfully!',
                icon: 'success',
                confirmButtonText: 'OK'
            });
        } catch (error) {
            console.error('Failed to submit answers', error);
            Swal.fire({
                title: 'Error',
                text: 'Failed to submit answers',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const getAnswerFeedback = (questionId, answerKey) => {
        const result = Array.isArray(results) && results.find(r => r.question_id === questionId);
        if (!result || !result.user_answer) return '';

        let user_answer = result.user_answer;

        if (result.correct) {
            return user_answer === answerKey ? 'correct' : '';
        } else {
            if (user_answer === answerKey) return 'incorrect';
            if (result.correct_answer === answerKey) return 'correct';
        }
        return '';
    };

    const handleImageLoad = (questionId, event) => {
        const imageWidth = event.target.naturalWidth;
        setImageSizes(prevSizes => ({
            ...prevSizes,
            [questionId]: imageWidth
        }));
    };

    if (loading) return <p className="text-gray-300">Loading...</p>;

    return (
        <div className="bg-gray-900 text-gray-200 min-h-screen p-6">
            {questions.length === 0 ? (
                <p className="text-center text-gray-400">No questions available.</p>
            ) : (
                <div className="container mx-auto max-w-3xl">
                    {questions.map((question) => (
                        <div key={question.question_id} className="my-8 bg-gray-800 px-8 py-8 rounded-lg shadow-md border border-gray-700">
                            <p className="mb-4 font-medium text-gray-200">{question.text}</p>
                            {question.image && question.image !== 'NULL' && (
                                <img
                                    src={question.image}
                                    alt="Question"
                                    onLoad={(event) => handleImageLoad(question.question_id, event)}
                                    className={`rounded-lg mb-5 ${imageSizes[question.question_id] && imageSizes[question.question_id] < 200 ? 'w-auto h-auto' : 'w-full h-auto'}`}
                                />
                            )}
                            <div className="mb-6">
                                {Object.entries(question.answers).map(([key, answer], index) => {
                                    const feedback = getAnswerFeedback(question.question_id, key);

                                    return (
                                        <button
                                            key={index}
                                            type="button"
                                            title={feedback}
                                            onClick={() => handleAnswerChange(question.question_id, key)}
                                            className={`block w-full text-left py-3 px-4 mb-3 rounded-lg text-sm transition-colors duration-200 ${feedback === 'correct'
                                                    ? 'bg-green-500 text-green-950'
                                                    : feedback === 'incorrect'
                                                        ? 'bg-red-500 text-red-950'
                                                        : answers[question.question_id] === key
                                                            ? 'bg-blue-300 text-blue-800'
                                                            : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                                                }`}
                                            disabled={submitted}
                                        >
                                            {answer}
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                    {submitted && (
                        <div className="my-8 bg-gray-800 p-6 rounded-lg shadow-md border border-gray-700">
                            <h3 className="text-xl font-semibold text-gray-200 mb-4">Results:</h3>
                            <p>Total Correct Answers: {correctCount}</p>
                            <p>Total Incorrect Answers: {incorrectCount}</p>
                            <p>Success Percentage: {percentage.toFixed(2)}%</p>
                        </div>
                    )}
                    <button
                        onClick={handleSubmit}
                        className="bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors duration-200 w-full mt-6"
                        disabled={submitted}
                    >
                        Submit Answers
                    </button>
                </div>
            )}
        </div>
    );
};

export default FullTest;
