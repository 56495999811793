import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from '../../../utils/tokenUtils';

const ClassDetail = () => {
    const { class_id } = useParams();
    const [classData, setClassData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchClassDetail() {
            try {
                const response = await api.get('/class');
                const classInfo = response.data.classes.find(c => c.class_id === class_id);
                if (classInfo) {
                    setClassData(classInfo);
                } else {
                    setError('Class not found.');
                }
                setLoading(false);
            } catch (err) {
                setError('Error fetching class details.');
                setLoading(false);
            }
        }
        fetchClassDetail();
    }, [class_id]);

    const deleteStudent = async (studentId) => {
        try {
            await api.delete(`/class/${class_id}/students/${studentId}`);
            // Remove the student from the state after successful deletion
            setClassData(prevClassData => ({
                ...prevClassData,
                students: prevClassData.students.filter(student => student.student_id !== studentId)
            }));
            setError(null); // Clear any previous errors
        } catch (err) {
            setError('Error deleting student from class.');
        }
    };

    if (loading) {
        return <div className="min-h-screen bg-gray-900 text-gray-200 flex flex-col items-center py-10 px-6"></div>;
    }

    if (error) {
        return <p className="text-center text-xl text-red-500">{error}</p>;
    }

    return (
        <div className="min-h-screen bg-gray-900 text-gray-200 flex flex-col items-center py-10 px-6">
            <h2 className="text-4xl font-bold mb-8 text-green-400">Class: {classData.class_name}</h2>
            <Link
                to="/classes"
                className="mb-8 text-lg text-blue-400 underline hover:text-blue-500 transition-colors"
            >
                Back to Class List
            </Link>
            {classData.students.length === 0 ? (
                <p className="text-lg text-gray-300">No students enrolled in this class.</p>
            ) : (
                <ul className="w-full max-w-4xl space-y-6">
                    {classData.students.map((student) => (
                        <li
                            key={student.student_id}
                            className="bg-gray-800 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105"
                        >
                            <div className="flex flex-col sm:flex-row justify-between items-center">
                                <p className="text-xl font-semibold text-gray-100">
                                    <strong>Student Name:</strong> {student.student_name}
                                </p>
                                <p className="mt-2 sm:mt-0 text-lg text-gray-400">
                                    <strong>Average Correct Answers:</strong> {student.avg_correct_answers.toFixed(2)}
                                </p>
                                <button 
                                    onClick={() => deleteStudent(student.student_id)}
                                    className="mt-4 sm:mt-0 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors"
                                >
                                    Remove Student
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ClassDetail;
