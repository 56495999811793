import React, { useState, useEffect } from 'react';
import api from '../../../../utils/tokenUtils';

const LogsViewer = () => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchLogs();
    }, []);

    const fetchLogs = async () => {
        try {
            const response = await api.get('/admin-logs');  // Fetch logs from the API
            setLogs(response.data.logs);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching logs:', error);
        }
    };

    return (
        <div className="flex flex-col p-6 mt-6">
            <h2 className="text-xl mb-4">Application Logs</h2>
            <div className="overflow-y-auto max-h-96 bg-gray-100 p-4 border rounded">
                {loading ? (
                    <p>Loading logs...</p>
                ) : (
                    <pre className="text-sm text-gray-800">
                        {logs.length ? logs.join('') : "No logs available."}
                    </pre>
                )}
            </div>
        </div>
    );
};

export default LogsViewer;
