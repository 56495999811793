import React, { useEffect, useState } from 'react';
import api from '../../../utils/tokenUtils';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { Bar, Pie } from 'react-chartjs-2';  // Import chart components
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

const UserProfile = () => {
    const { id } = useParams(); // Get the user ID from the URL parameters
    const [userData, setUserData] = useState(null);
    const [stats, setStats] = useState(null);
    const [loadingUser, setLoadingUser] = useState(true);
    const [loadingStats, setLoadingStats] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchUserProfile();
        fetchStats();
    }, [id]);

    const fetchUserProfile = async () => {
        try {
            const response = await api.get(`https://api.egzamin-inf.pl/profile/${id}`);
            setUserData(response.data.data);
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred while fetching the profile.');
        } finally {
            setLoadingUser(false);
        }
    };

    const fetchStats = async () => {
        try {
            // Fetch user answers
            const answersResponse = await api.post('/get_answers');
            const answers = answersResponse.data.answers;
            const total = answers.length;
            const correctCount = answers.filter(answer => answer.split(',')[3] === 'True').length;
            const incorrectCount = total - correctCount;  // Count incorrect answers
            const percentage = total > 0 ? (correctCount / total) * 100 : 0;

            setStats({ percentage, total, correctCount, incorrectCount });
        } catch (error) {
            console.error('Nie udało się pobrać statystyk', error);
            Swal.fire({
                title: 'Błąd',
                text: 'Nie udało się pobrać statystyk',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } finally {
            setLoadingStats(false);
        }
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    const renderBarChart = () => {
        const data = {
            labels: ['Correct', 'Incorrect'],
            datasets: [
                {
                    label: 'Answers',
                    data: [stats.correctCount, stats.incorrectCount],
                    backgroundColor: ['#4CAF50', '#F44336'],  // Green for correct, Red for incorrect
                }
            ]
        };

        const options = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: 'Correct vs Incorrect Answers',
                }
            }
        };

        return <Bar data={data} options={options} />;
    };

    const renderPieChart = () => {
        const data = {
            labels: ['Correct', 'Incorrect'],
            datasets: [
                {
                    data: [stats.correctCount, stats.incorrectCount],
                    backgroundColor: ['#4CAF50', '#F44336'],  // Green for correct, Red for incorrect
                }
            ]
        };

        return <Pie data={data} />;
    };

    return (
        <>
            {
                userData && stats ? (
                    <>
                        <div className="container mx-auto p-4">
                            <h1 className="text-2xl font-bold">User Profile</h1>
                            <div className="mt-4">
                                <h2 className="text-xl">Username: {userData[1]}</h2>
                                <p>Registration Date: {new Date(userData[2]).toLocaleDateString()}</p>
                                <p>Badges: {userData[3]?.join(', ') || 'No badges'}</p>
                                <p>Achievements: {userData[4]?.join(', ') || 'No achievements'}</p>
                                <p>Status: {userData[5] ? 'Verified' : 'Not Verified'}</p>
                            </div>
                        </div>

                        <div className="container mx-auto p-4">
                            <h1 className="text-2xl font-bold">User Stats</h1>
                            <div className="mt-4">
                                <p>Łączna liczba odpowiedzi: {stats.total}</p>
                                <p>Liczba poprawnych odpowiedzi: {stats.correctCount}</p>
                                <p>Liczba niepoprawnych odpowiedzi: {stats.incorrectCount}</p>
                                <p>Procent poprawnych odpowiedzi: {stats.percentage.toFixed(2)}%</p>
                            </div>

                            <div className="mt-6">
                                <h2 className="text-xl font-bold mb-4">Bar Chart - Answers</h2>
                                {renderBarChart()}
                            </div>

                            <div className="mt-6">
                                <h2 className="text-xl font-bold mb-4">Pie Chart - Correct vs Incorrect</h2>
                                {renderPieChart()}
                            </div>
                        </div>
                    </>
                ) : (
                    <p className="text-center italic mt-4">Ładowanie...</p>
                )
            }
        </>
    );
};

export default UserProfile;
