import api from './tokenUtils';

export const getQuestions = async (exam, tags, examType, question_id) => {
    exam = exam ?? 2;
    if (examType) {
        if (examType === "1") {
            examType = "one_random_question";
        } else if (examType === "2") {
            examType = "one_theme_question";
        } else if (examType === "3") {
            examType = "one_exact_question";
        }
    } else {
        examType = "one_random_question";
    }
    const response = await api.post('/get_questions', {
        exam: exam,
        type: examType,
        tags,
        question_id,
    });
    return response.data;
};

export const checkAnswer = async (questionId, answerKey) => {
    const response = await api.post('/check_answers', {
        answers: [
            {
                question_id: questionId,
                answer: answerKey,
            },
        ],
    });
    return response.data;
};

export const getGuidesByQuestion = async (questionId) => {
    const response = await api.post(`/guide/question/${questionId}`);
    return response.data;
};

export const getGuideDetails = async (guideId) => {
    const response = await api.post(`/guide/id/${guideId}`);
    return response.data;
};

export const getAnswers = async () => {
    const response = await api.post('/get_answers');
    return response.data.answers;
};

export const getTagsList = async () => {
    const response = await api.post('/tags_list');
    return response.data.tags;
};

export default api;
