import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../utils/tokenUtils';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend, Title } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import LogsViewer from './Logs/LogsViewer';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend, Title, zoomPlugin);


const AdminPage = () => {
    const [loading, setLoading] = useState(true);
    const [usersCount, setUsersCount] = useState(0);
    const [usersOnline, setUsersOnline] = useState(0);
    const [usersPremium, setUsersPremium] = useState(2);  // You might want to update this once real data is available
    const [pageViews, setPageViews] = useState("---");
    const [creditsUsed, setCreditsUsed] = useState(0);
    const [creditsLimit, setCreditsLimit] = useState(0);
    const [spaceUsed, setSpaceUsed] = useState(0);
    const [objectsCount, setObjectsCount] = useState(0);
    const [userActivities, setUserActivities] = useState([]);
    const [usersList, setUsersList] = useState([]);  // State to store users list
    const [guidesList, setGuidesList] = useState([]);  // State to store guides list
    const [dockerStats, setDockerStats] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await api.post('/admin-data');  // Fetch from the API
            const data = response.data;

            // Update states
            setUsersCount(data.users_count);
            setUsersOnline(data.online_users_count);
            setCreditsUsed(data.credits_used);
            setCreditsLimit(data.credits_limit);
            setSpaceUsed(data.space_used);
            setObjectsCount(data.objects_count);
            setUserActivities(data.online_users_list);
            setUsersList(data.users_list);  // Set users list from API
            setGuidesList(data.guides_list);  // Set guides list from API
            setDockerStats(data.docker_stats);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching admin data:', error);
        }
    };

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString(); // Format date to a readable form
    };

    const dockerTimestamps = dockerStats.map(stat => new Date(stat.timestamp * 1000).toLocaleTimeString());
    const cpuUsageData = dockerStats.map(stat => {
        return {
            frontend: stat.containers.find(c => c.container_name === 'frontend').cpu_percent,
            backend: stat.containers.find(c => c.container_name === 'backend').cpu_percent,
            db: stat.containers.find(c => c.container_name === 'db').cpu_percent,
            redis: stat.containers.find(c => c.container_name === 'redis').cpu_percent
        };
    });

    const memoryUsageData = dockerStats.map(stat => {
        return {
            frontend: stat.containers.find(c => c.container_name === 'frontend').memory_usage_mb,
            backend: stat.containers.find(c => c.container_name === 'backend').memory_usage_mb,
            db: stat.containers.find(c => c.container_name === 'db').memory_usage_mb,
            redis: stat.containers.find(c => c.container_name === 'redis').memory_usage_mb
        };
    });

    const cpuChartData = {
        labels: dockerTimestamps,
        datasets: [
            {
                label: 'Frontend CPU (%)',
                data: cpuUsageData.map(d => d.frontend),
                borderColor: 'rgba(54, 162, 235, 1)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                fill: true,
                tension: 0.4,
                pointRadius: 0
            },
            {
                label: 'Backend CPU (%)',
                data: cpuUsageData.map(d => d.backend),
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                fill: true,
                tension: 0.4,
                pointRadius: 0
            },
            {
                label: 'DB CPU (%)',
                data: cpuUsageData.map(d => d.db),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
                tension: 0.4,
                pointRadius: 0
            },
            {
                label: 'Redis CPU (%)',
                data: cpuUsageData.map(d => d.redis),
                borderColor: 'rgba(153, 102, 255, 1)',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                fill: true,
                tension: 0.4,
                pointRadius: 0
                
            }
        ]
    };

    const memoryChartData = {
        labels: dockerTimestamps,
        datasets: [
            {
                label: 'Frontend Memory (MB)',
                data: memoryUsageData.map(d => d.frontend),
                borderColor: 'rgba(54, 162, 235, 1)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                fill: true,
                tension: 0.4,
                pointRadius: 0
            },
            {
                label: 'Backend Memory (MB)',
                data: memoryUsageData.map(d => d.backend),
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                fill: true,
                tension: 0.4,
                pointRadius: 0
            },
            {
                label: 'DB Memory (MB)',
                data: memoryUsageData.map(d => d.db),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
                tension: 0.4,
                pointRadius: 0
            },
            {
                label: 'Redis Memory (MB)',
                data: memoryUsageData.map(d => d.redis),
                borderColor: 'rgba(153, 102, 255, 1)',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                fill: true,
                tension: 0.4,
                pointRadius: 0
            }
        ]
    };

    const chartOptions = {
        responsive: true,
        scales: {
            x: {
                ticks: {
                    maxTicksLimit: 10
                }
            },
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x'
                },
                zoom: {
                    enabled: true,
                    mode: 'x',
                    wheel: {
                        enabled: true
                    },
                    pinch: {
                        enabled: true
                    },
                    drag: {
                        enabled: true
                    }
                }
            }
        }
    };

    const resetZoom = (chartRef) => {
        chartRef.resetZoom();
    };

    return (
        <>
            {/* Statistics Cards */}
            <main className='flex justify-center flex-wrap gap-2 md:gap-8 py-7'>
                {/* Users Count */}
                <div className="bg-white flex flex-col justify-between items-center w-48 h-48 md:w-80 md:h-52 rounded-2xl p-6 drop-shadow-lg transition-transform transform hover:scale-105">
                    <div className="flex justify-between items-center w-full">
                        <div className="bg-blue-100 p-3 rounded-full shadow-inner shadow-blue-400">
                            <i className="fa-solid fa-user mx-1 text-[32px] text-blue-500"></i>
                        </div>
                        {loading ? (
                            <i className="fa-solid fa-rotate-right fa-spin text-[32px]"></i>
                        ) : (
                            <span className="text-gray-700 text-4xl font-semibold">{usersCount.toLocaleString()}</span>
                        )}
                    </div>
                    <div className="w-full mt-4 text-center text-sm text-gray-500 font-medium">
                        Users Count
                    </div>
                </div>

                <div className="bg-white flex flex-col justify-between items-center w-48 h-48 md:w-80 md:h-52 rounded-2xl p-6 drop-shadow-lg transition-transform transform hover:scale-105">
                    <div className="flex justify-between items-center w-full">
                        <div className="bg-green-100 p-3 rounded-full shadow-inner shadow-green-400">
                            <i class="fa-solid fa-square-poll-vertical mx-1 text-[32px] text-green-500"></i>
                        </div>
                        {
                            loading ? (
                                <i class="fa-solid fa-rotate-right fa-spin text-[32px]"></i>
                            ) :
                                <span className="text-gray-700 text-4xl font-semibold">{usersOnline.toLocaleString()}</span>
                        }
                    </div>
                    <div className="w-full mt-4 text-center text-sm text-gray-500 font-medium">
                        Users Online
                    </div>
                </div>
                <div className="bg-white flex flex-col justify-between items-center w-48 h-48 md:w-80 md:h-52 rounded-2xl p-6 drop-shadow-lg transition-transform transform hover:scale-105">
                    <div className="flex justify-between items-center w-full">
                        <div className="bg-red-100 p-3 rounded-full shadow-inner shadow-red-400">
                            <i className="fa-solid fa-dollar-sign mx-2 text-[32px] text-red-500"></i>
                        </div>
                        {
                            loading ? (
                                <i class="fa-solid fa-rotate-right fa-spin text-[32px]"></i>
                            ) :
                                <span className="text-gray-700 text-4xl font-semibold">{usersPremium.toLocaleString()}</span>
                        }
                    </div>
                    <div className="w-full mt-4 text-center text-sm text-gray-500 font-medium">
                        Premium Users
                    </div>
                </div>

                <div className="bg-white flex flex-col justify-between items-center w-48 h-48 md:w-80 md:h-52 rounded-2xl p-6 drop-shadow-lg transition-transform transform hover:scale-105">
                    <div className="flex justify-between items-center w-full">
                        <div className="bg-yellow-100 p-3 rounded-full shadow-inner shadow-yellow-400">
                            <i class="fa-solid fa-eye text-[32px] text-yellow-500"></i>
                        </div>
                        {
                            loading ? (
                                <i class="fa-solid fa-rotate-right fa-spin text-[32px]"></i>
                            ) :
                                <span className="text-gray-700 text-4xl font-semibold">{pageViews.toLocaleString()}</span>
                        }
                    </div>
                    <div className="w-full mt-4 text-center text-sm text-gray-500 font-medium">
                        Page Views
                    </div>
                </div>

                <div className="bg-white flex flex-col justify-between items-center w-48 h-48 md:w-80 md:h-52 rounded-2xl p-6 drop-shadow-lg transition-transform transform hover:scale-105">
                    <div className="flex justify-between items-center w-full">
                        <div className="bg-cyan-100 py-3 px-[10px] rounded-full shadow-inner shadow-cyan-400">
                            <i class="fa-solid fa-cloud-arrow-down text-[32px] text-cyan-800"></i>
                        </div>
                        {
                            loading ? (
                                <i class="fa-solid fa-rotate-right fa-spin text-[32px]"></i>
                            ) :
                                <span className="text-gray-700 text-xl md:text-4xl font-semibold">{creditsUsed}/{creditsLimit}</span>
                        }
                    </div>
                    <div className="w-full mt-4 text-center text-sm text-gray-500 font-medium">
                        Cloudinary Credits
                    </div>
                </div>

                <div className="bg-white flex flex-col justify-between items-center w-48 h-48 md:w-80 md:h-52 rounded-2xl p-6 drop-shadow-lg transition-transform transform hover:scale-105">
                    <div className="flex justify-between items-center w-full">
                        <div className="bg-lime-100 py-3 px-[10px] rounded-full shadow-inner shadow-lime-400">
                            <i class="fa-solid fa-cloud-arrow-down text-[32px] text-lime-800"></i>
                        </div>
                        {
                            loading ? (
                                <i class="fa-solid fa-rotate-right fa-spin text-[32px]"></i>
                            ) :
                                <span className="text-gray-700 text-xl md:text-4xl font-semibold">{parseFloat(spaceUsed / 1048576).toFixed(1)}Mb</span>
                        }
                    </div>
                    <div className="w-full mt-4 text-center text-sm text-gray-500 font-medium">
                        Cloudinary Disk Used
                    </div>
                </div>
                <div className="bg-white flex flex-col justify-between items-center w-48 h-48 md:w-80 md:h-52 rounded-2xl p-6 drop-shadow-lg transition-transform transform hover:scale-105">
                    <div className="flex justify-between items-center w-full">
                        <div className="bg-purple-100 py-3 px-[10px] rounded-full shadow-inner shadow-purple-400">
                            <i class="fa-solid fa-photo-film text-[32px] text-purple-800"></i>
                        </div>
                        {
                            loading ? (
                                <i class="fa-solid fa-rotate-right fa-spin text-[32px]"></i>
                            ) :
                                <span className="text-gray-700 text-xl md:text-4xl font-semibold">{objectsCount}</span>
                        }
                    </div>
                    <div className="w-full mt-4 text-center text-sm text-gray-500 font-medium">
                        Cloudinary Objects
                    </div>
                </div>

            </main>

            {/* Docker Stats Charts */}
            <div className="flex flex-col p-6">
                <h2 className="text-xl mb-4">Docker CPU Usage</h2>
                <Line data={cpuChartData} options={chartOptions} />
            </div>

            <div className="flex flex-col p-6 mt-6">
                <h2 className="text-xl mb-4">Docker Memory Usage</h2>
                <Line data={memoryChartData} options={chartOptions} />
            </div>

            {/* Data Tables */}
            <hr />
            <div className="w-full py-6 text-center text-2xl">
                Tables
            </div>
            <hr />

            {/* Users List Table */}
            <div className="flex flex-col p-6">
                <h2 className="text-xl mb-4">Registered Users</h2>
                <div className="overflow-y-auto max-h-96">
                    <table className="min-w-full border">
                        <thead>
                            <tr>
                                <th className="border-b py-2 px-4">User ID</th>
                                <th className="border-b py-2 px-4">Username</th>
                                <th className="border-b py-2 px-4">Email</th>
                                <th className="border-b py-2 px-4">Registration Date</th>
                                <th className="border-b py-2 px-4">Verified</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr><td colSpan="5" className="text-center py-4">Loading...</td></tr>
                            ) : (
                                usersList.map((user) => (
                                    <tr key={user.user_id}>
                                        <td className="border-b py-2 px-4">{user.user_id}</td>
                                        <td className="border-b py-2 px-4">{user.username}</td>
                                        <td className="border-b py-2 px-4">{user.email}</td>
                                        <td className="border-b py-2 px-4">{formatTime(user.registration_date)}</td>
                                        <td className="border-b py-2 px-4">{user.is_verified ? "Yes" : "No"}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Guides List Table */}
            <div className="flex flex-col p-6 mt-6">
                <h2 className="text-xl mb-4">Guides List</h2>
                <div className="overflow-y-auto max-h-96">
                    <table className="min-w-full border">
                        <thead>
                            <tr>
                                <th className="border-b py-2 px-4">Guide ID</th>
                                <th className="border-b py-2 px-4">Title</th>
                                <th className="border-b py-2 px-4">Author</th>
                                <th className="border-b py-2 px-4">Creation Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr><td colSpan="4" className="text-center py-4">Loading...</td></tr>
                            ) : (
                                guidesList.map((guide) => (
                                    <tr key={guide.guide_id}>
                                        <td className="border-b py-2 px-4">{guide.guide_id}</td>
                                        <td className="border-b py-2 px-4 text-blue-600">
                                            <Link to={`/guide/id/${guide.guide_id}`}>{guide.title}</Link>
                                        </td>
                                        <td className="border-b py-2 px-4">{guide.author}</td>
                                        <td className="border-b py-2 px-4">{formatTime(guide.creation_date)}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* User Activities Table (Online Users) */}
            <div className="flex flex-col p-6 mt-6">
                <h2 className="text-xl mb-4">Online User Activities</h2>
                <div className="overflow-y-auto max-h-96">
                    <table className="min-w-full border">
                        <thead>
                            <tr>
                                <th className="border-b py-2 px-4">User ID</th>
                                <th className="border-b py-2 px-4">Last Activity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr><td colSpan="2" className="text-center py-4">Loading...</td></tr>
                            ) : (
                                Object.entries(userActivities)
                                    .sort((a, b) => b[1] - a[1])  // Sort by activity time
                                    .map(([userId, activityTime], index) => (
                                        <tr key={index}>
                                            <td className="border-b py-2 px-4">{userId}</td>
                                            <td className="border-b py-2 px-4">{formatTime(activityTime * 1000)}</td>
                                        </tr>
                                    ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <LogsViewer />
        </>
    );
};

export default AdminPage;







