import React, { useEffect, useState } from 'react';
import { Chart, RadarController, RadialLinearScale, PointElement, LineElement, Filler } from 'chart.js';
import { getAnswers, getTagsList } from '../../../../../utils/api'; // Adjust the import path if necessary

Chart.register(RadarController, RadialLinearScale, PointElement, LineElement, Filler);

const RadarChart = ({ onClose }) => {
    const [chartData, setChartData] = useState(null);

    const fetchStats = async () => {
        try {
            const answers = await getAnswers();
            const tagsResponse = await getTagsList();
            const tags = tagsResponse.reduce((acc, [id, name]) => {
                acc[id] = name;
                return acc;
            }, {});

            const tagStats = {};
            answers.forEach(answer => {
                const [, , , isCorrect, tagId] = answer.split(',');
                if (!tagStats[tagId]) {
                    tagStats[tagId] = { total: 0, correct: 0 };
                }
                tagStats[tagId].total += 1;
                if (isCorrect === 'True') {
                    tagStats[tagId].correct += 1;
                }
            });

            const labels = Object.keys(tagStats).map(tagId => tags[tagId] || 'Unknown Tag');
            const data = Object.keys(tagStats).map(tagId => {
                const { total, correct } = tagStats[tagId];
                return total > 0 ? (correct / total) * 100 : 0;
            });

            setChartData({ labels, data });
        } catch (error) {
            console.error('Failed to fetch stats', error);
        }
    };

    useEffect(() => {
        fetchStats();
    }, []);

    useEffect(() => {
        if (chartData) {
            const ctx = document.getElementById('tagStatsChart').getContext('2d');
            const chartInstance = new Chart(ctx, {
                type: 'radar',
                data: {
                    labels: chartData.labels,
                    datasets: [{
                        label: 'Procent poprawnych odpowiedzi',
                        data: chartData.data,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        r: {
                            ticks: { beginAtZero: true },
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false, // Make it responsive
                }
            });

            return () => {
                chartInstance.destroy(); // Cleanup the chart instance on unmount
            };
        }
    }, [chartData]);

    return (
        <div style={{ width: '100%', height: '400px' }}> {/* Ensure responsive height */}
            <canvas id="tagStatsChart"></canvas>
        </div>
    );
};

export default RadarChart;
