// src/components/Pages/Tasks/TasksList.js

import React, { useEffect, useState } from 'react';
import api from '../../../utils/tokenUtils'; // Import your axios instance

const TasksList = () => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const response = await api.get(`/class/19/tasks`); // Update this with the correct class ID
                setTasks(response.data);
            } catch (err) {
                setError(err.message || "An error occurred");
            } finally {
                setLoading(false);
            }
        };

        fetchTasks();
    }, []);

    const formatTime = (timestamp) => {
        const date = new Date(timestamp * 1000); // Ensure timestamp is in seconds
        return date.toLocaleString(); // Format date to a readable form
    };

    if (loading) {
        return (
            <div className="min-h-screen bg-gray-900 text-gray-200 flex justify-center items-center">
                Loading tasks...
            </div>
        );
    }
    if (error) {
        return <div className="text-center text-xl text-red-500">{error}</div>;
    }

    return (
        <div className="min-h-screen bg-gray-900 text-gray-200 flex flex-col items-center py-10 px-6">
            <h1 className="text-4xl font-bold mb-8 text-green-400">Tasks List</h1>
            {tasks.length === 0 ? (
                <p className="text-lg text-gray-300">No tasks available for this class.</p>
            ) : (
                <ul className="w-full max-w-4xl space-y-6">
                    {tasks.map(task => (
                        <li key={task.task_id} className="bg-gray-800 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                            <h3 className="text-xl font-semibold text-gray-100">{task.task_comment}</h3>
                            <p className="mt-2 text-lg text-gray-400"><strong>Type:</strong> {task.task_type}</p>
                            <p className="text-gray-400"><strong>Created at:</strong> {formatTime(task.creation_time)}</p>
                            <p className="text-gray-400"><strong>Expires at:</strong> {formatTime(task.expires)}</p>
                            <p className="text-gray-400"><strong>Completed at:</strong> {task.completed_at ? formatTime(task.completed_at) : 'Not completed'}</p>
                            {task.tags_id.length > 0 && (
                                <p className="text-green-400">
                                    <strong>Tags:</strong> {task.tags_id.join(', ')}
                                </p>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default TasksList;
